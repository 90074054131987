// extracted by mini-css-extract-plugin
export var mt0 = "Tags-module--mt-0--3Bvzp";
export var pt0 = "Tags-module--pt-0--_uggT";
export var mb0 = "Tags-module--mb-0--2jrsR";
export var pb0 = "Tags-module--pb-0--1HMqF";
export var ml0 = "Tags-module--ml-0--2Ag0d";
export var pl0 = "Tags-module--pl-0--Lq3lT";
export var mr0 = "Tags-module--mr-0--3LzGf";
export var pr0 = "Tags-module--pr-0--RcTuL";
export var mt5 = "Tags-module--mt-5--3EPZR";
export var pt5 = "Tags-module--pt-5--2QrQe";
export var mb5 = "Tags-module--mb-5--l29iP";
export var pb5 = "Tags-module--pb-5--2qr5F";
export var ml5 = "Tags-module--ml-5--3fhhu";
export var pl5 = "Tags-module--pl-5--3ZIrI";
export var mr5 = "Tags-module--mr-5--2FNLk";
export var pr5 = "Tags-module--pr-5--SQYuu";
export var mt10 = "Tags-module--mt-10--3hRHJ";
export var pt10 = "Tags-module--pt-10--3aM7c";
export var mb10 = "Tags-module--mb-10--pR-6G";
export var pb10 = "Tags-module--pb-10--269RB";
export var ml10 = "Tags-module--ml-10--2BZfh";
export var pl10 = "Tags-module--pl-10--168bL";
export var mr10 = "Tags-module--mr-10--1ZoTh";
export var pr10 = "Tags-module--pr-10--2L5Jr";
export var mt15 = "Tags-module--mt-15--111xM";
export var pt15 = "Tags-module--pt-15--2GqHq";
export var mb15 = "Tags-module--mb-15--3KLSe";
export var pb15 = "Tags-module--pb-15--3Vgv-";
export var ml15 = "Tags-module--ml-15--3X5go";
export var pl15 = "Tags-module--pl-15--8tvwm";
export var mr15 = "Tags-module--mr-15--tp43M";
export var pr15 = "Tags-module--pr-15--3zK5i";
export var mt20 = "Tags-module--mt-20--1ICvu";
export var pt20 = "Tags-module--pt-20--s3PHR";
export var mb20 = "Tags-module--mb-20--CJ7iw";
export var pb20 = "Tags-module--pb-20--2L5nG";
export var ml20 = "Tags-module--ml-20--3-hbA";
export var pl20 = "Tags-module--pl-20--YzmVW";
export var mr20 = "Tags-module--mr-20--1VeGc";
export var pr20 = "Tags-module--pr-20--3drcc";
export var mt25 = "Tags-module--mt-25--3zgXs";
export var pt25 = "Tags-module--pt-25--5KLig";
export var mb25 = "Tags-module--mb-25--Eo1lK";
export var pb25 = "Tags-module--pb-25--ULYSw";
export var ml25 = "Tags-module--ml-25--bgxqv";
export var pl25 = "Tags-module--pl-25--CHnJM";
export var mr25 = "Tags-module--mr-25--1NNbN";
export var pr25 = "Tags-module--pr-25--3eOic";
export var mt30 = "Tags-module--mt-30--2BhUk";
export var pt30 = "Tags-module--pt-30--2gt0X";
export var mb30 = "Tags-module--mb-30--10lKr";
export var pb30 = "Tags-module--pb-30--8I_gb";
export var ml30 = "Tags-module--ml-30--27kLG";
export var pl30 = "Tags-module--pl-30--QfCL1";
export var mr30 = "Tags-module--mr-30--3Y_bS";
export var pr30 = "Tags-module--pr-30--2-Yzo";
export var mt35 = "Tags-module--mt-35--3atqg";
export var pt35 = "Tags-module--pt-35--2WWAM";
export var mb35 = "Tags-module--mb-35--w0ygi";
export var pb35 = "Tags-module--pb-35--yZmUG";
export var ml35 = "Tags-module--ml-35--1hdL5";
export var pl35 = "Tags-module--pl-35--28Hbm";
export var mr35 = "Tags-module--mr-35--3UtjI";
export var pr35 = "Tags-module--pr-35--1ltk2";
export var mt40 = "Tags-module--mt-40--2LPsE";
export var pt40 = "Tags-module--pt-40--u7E2a";
export var mb40 = "Tags-module--mb-40--3cZxC";
export var pb40 = "Tags-module--pb-40--38dHZ";
export var ml40 = "Tags-module--ml-40--4hGwv";
export var pl40 = "Tags-module--pl-40--3Hy6h";
export var mr40 = "Tags-module--mr-40--1IA4j";
export var pr40 = "Tags-module--pr-40--1_t6Q";
export var mt45 = "Tags-module--mt-45--8zDn5";
export var pt45 = "Tags-module--pt-45--3m7d5";
export var mb45 = "Tags-module--mb-45--2Dm6o";
export var pb45 = "Tags-module--pb-45--1eu8Q";
export var ml45 = "Tags-module--ml-45--11y6W";
export var pl45 = "Tags-module--pl-45--1_0XZ";
export var mr45 = "Tags-module--mr-45--qAiKM";
export var pr45 = "Tags-module--pr-45--2S4li";
export var mt50 = "Tags-module--mt-50--3supK";
export var pt50 = "Tags-module--pt-50--3JN3S";
export var mb50 = "Tags-module--mb-50--36yJF";
export var pb50 = "Tags-module--pb-50--1Lcma";
export var ml50 = "Tags-module--ml-50--3O52R";
export var pl50 = "Tags-module--pl-50--WDt_t";
export var mr50 = "Tags-module--mr-50--2D0AA";
export var pr50 = "Tags-module--pr-50--H-Oap";
export var mt75 = "Tags-module--mt-75--gJdRu";
export var pt75 = "Tags-module--pt-75--16y3W";
export var mb75 = "Tags-module--mb-75--3WguK";
export var pb75 = "Tags-module--pb-75--2DdQN";
export var ml75 = "Tags-module--ml-75--1Cvud";
export var pl75 = "Tags-module--pl-75--3eEEO";
export var mr75 = "Tags-module--mr-75--3uDof";
export var pr75 = "Tags-module--pr-75--3BG0A";
export var mt100 = "Tags-module--mt-100--1a6-h";
export var pt100 = "Tags-module--pt-100--30tav";
export var mb100 = "Tags-module--mb-100--3q56W";
export var pb100 = "Tags-module--pb-100--22rt_";
export var ml100 = "Tags-module--ml-100--2HSAR";
export var pl100 = "Tags-module--pl-100--nZ29I";
export var mr100 = "Tags-module--mr-100--j3Evk";
export var pr100 = "Tags-module--pr-100--23F7H";
export var zIndex1 = "Tags-module--z-index-1--1ASc5";
export var zIndex2 = "Tags-module--z-index-2--3oDJ3";
export var zIndex3 = "Tags-module--z-index-3--3wcK7";
export var zIndex4 = "Tags-module--z-index-4--1VuQF";
export var zIndex5 = "Tags-module--z-index-5--1TVf2";
export var zIndex6 = "Tags-module--z-index-6--1Sj_r";
export var zIndex7 = "Tags-module--z-index-7--l7aKD";
export var zIndex8 = "Tags-module--z-index-8--2d8ox";
export var zIndex9 = "Tags-module--z-index-9--3m0Nd";
export var zIndex10 = "Tags-module--z-index-10--1y4Oe";
export var zIndex20 = "Tags-module--z-index-20--1zyM8";
export var zIndex30 = "Tags-module--z-index-30--3Q2NL";
export var zIndex40 = "Tags-module--z-index-40--3FpGT";
export var zIndex50 = "Tags-module--z-index-50--2T9C8";
export var zIndex60 = "Tags-module--z-index-60--3eBol";
export var zIndex70 = "Tags-module--z-index-70--3TGIa";
export var zIndex80 = "Tags-module--z-index-80--37gfa";
export var zIndex90 = "Tags-module--z-index-90--1psDw";
export var zIndex100 = "Tags-module--z-index-100--1gjED";
export var link = "Tags-module--link--3XZ-_";
export var uppercase = "Tags-module--uppercase--yRb7q";
export var more = "Tags-module--more--3YAEI";
export var button = "Tags-module--button--3CQIn";
export var send = "Tags-module--send--24lEL";
export var sm = "Tags-module--sm--2sGVT";
export var tagList = "Tags-module--tagList--q-7Wp";
export var tagItem = "Tags-module--tagItem--3rymj";
export var dark = "Tags-module--dark--36-x1";
export var tag = "Tags-module--tag--3qfB_";